
.choseMain-main {
	height: 100%;
	.content {
		width: 100%;
		background: url(../../assets/images/choseMain_bg.jpg) no-repeat;
		background-size: 100% 100%;
		height: calc(~'100% - 220px');
		position: relative;
		.main_box {
			width: 90%;
			position: absolute;
			color: #202020;
			left: 50%;
			top: 50%;
			-webkit-transform: translateY(-50%) translateX(-50%);
			transform: translateY(-50%) translateX(-50%);
			.title1 {
				font-size: 20px;
				line-height: 50px;
			}
			.title2 {
				font-size: 24px;
				line-height: 50px;
			}
			.title3 {
				font-size: 20px;
				color: #666;
				line-height: 50px;
			}
			.row {
				display: flex;
				justify-content: space-between;
				margin-top: 20px;
				.row_box {
					width: 287px;
					position: relative;
					background: white;
					cursor: pointer;
                    border-radius: 3px;
					.top {
						height: 160px;
						padding-top: 52px;
						text-align: center;
						font-size: 20px;
						background: #f8f9fd;
					}
					.icon {
						border-radius: 50%;
						border: 1px solid #f8f9fd;
						position: absolute;
						top: 110px;
						left: 50%;
						-webkit-transform: translateX(-50%);
						transform: translateX(-50%);
						width: 100px;
						height: 100px;
						background: white;
						.icon_box {
							display: block;
							width: 60px;
							height: 60px;
							margin-top: 20px;
							margin-left: 20px;
						}
					}
					.info {
						padding: 70px 32px;
						font-size: 14px;
						color: #666;
						position: relative;
						height: 340px;
						.btn {
							width: 180px;
							height: 32px;
							position: absolute;
							bottom: 32px;
							left: 50%;
							-webkit-transform: translateX(-50%);
							transform: translateX(-50%);
							background: linear-gradient(
								90deg,
								#32d3cb 0%,
								#269bef 100%
							);
							border-radius: 3px;
							color: white;
							font-size: 14px;
							text-align: center;
							line-height: 32px;
						}
					}
				}

                .row_box:hover {
                    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
                    .top {
                        color: white;
                        background: linear-gradient(360deg, #32D3CB 0%, #269BEF 100%);
                        border-top-left-radius: 3px;
                        border-top-right-radius: 3px;
                    }
                    .icon {
                        border: 1px solid #60ADF9;
                    }
                }

				.first {
					.icon_box {
						background: url(../../assets/images/jsdw.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.first:hover {
					.icon_box {
						background: url(../../assets/images/jsdw1.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.second {
					.icon_box {
						background: url(../../assets/images/sjgl.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.second:hover {
					.icon_box {
						background: url(../../assets/images/sjgl1.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.third {
					.icon_box {
						background: url(../../assets/images/xsdw.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}

				.third:hover {
					.icon_box {
						background: url(../../assets/images/xsdw1.png) no-repeat;
						background-size: 60px 60px;
						background-position: center center;
					}
				}
			}
		}
	}

    .foot {
        height: 220px;
        padding-top: 30px;
        p {
            font-size: 14px;
            color: #999;
            margin-bottom: 13px;
        }
    }
}
